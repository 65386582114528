.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    height: 100%;
    background-color: $white;

    padding: 0 $buildingUnit;

    @include min-tablet {
        padding: 0 $buildingUnit * 10;
    }

    @include min-desktop-hd {
        padding: 0 $buildingUnit * 20;
    }
}
