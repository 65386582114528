@import '~material-design-icons/iconfont/material-icons.css';
/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/index.scss";

html,
body {
  overscroll-behavior: contain;
  margin: 0;
  height: 100%;
}

.help-modal .modal-wrapper,
.rating-modal .modal-wrapper {
  height: auto;
  width: 85%;
}


.table-modal .modal-wrapper,
.scan-in-modal .modal-wrapper,
.scan-out-modal .modal-wrapper {
  height: auto;
  width: 100%;
  border-radius: $buildingUnit;

  @include min-tablet {
    width: 85%;
  }
}

.scan-in-modal .modal-wrapper,
.scan-out-modal .modal-wrapper {
  height: auto;
  width: 100%;
  border-radius: $buildingUnit;

  @include min-tablet {
    width: 50%;
  }
}
