.input {
    padding: $buildingUnit;
    border: 1px solid $pargoGrey;
    border-radius: $buildingUnit / 4;
    width: 100%;

    font-family: 'open-sans', 'Helvetica' !important;
    font-size: $baseFontSize;
    line-height: $baseLineHeight;
    color: #414141;


    &.ng-invalid.ng-touched {
        border: 1px solid $errorBackground;
        color: $errorFont;
    }
}

.input__text {
    @extend .input;
}

.input__text+.input__error {
    margin-bottom: $buildingUnit * 2;
}

.input__error {
    font-size: $baseFontSize * 0.8;
    line-height: $baseLineHeight;
    color: $errorFont;
    background-color: $errorBackground;
    padding: $buildingUnit / 3 $buildingUnit;
    border-radius: $buildingUnit / 4;
}

.select {
    @extend .input;
    height: $buildingUnit * 4;
}

label {

    font-family: 'Roboto', 'Helvetica' !important;
    font-size: $baseFontSize * 0.9;
    line-height: $baseLineHeight;

    display: flex;
    flex-direction: column;

    font-weight: 700;
}
