/** @format */

*::before,
*,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
}
